import { match } from "@pomebile/shared/tagged-union"
import { AppEvent, AppScreen, AppState, calculateRewardScreen } from "./rewardModel"
import { AppApiContext, queryRewardVoucher } from "./api/webRoutes"
import { GeneralErrorScreen } from "./screens/GeneralError"
import {
  createAuthContext,
  createLoggingContext,
  InitialData,
  ShellState,
} from "./sharedShellLogic"
import { DEBUG_RESTORE_KEY, DEBUG_STORAGE_KEY } from "./components/DevTools"
import { anonymousIdPromise } from "./utils/segment"
import { FetchingRewardVoucher } from "./screens/RewardFetchingRewardVoucher"
import { ClaimReward } from "./screens/ClaimReward"

export type Progression = "none"

export const calculateProgression = (): Progression => "none"

export const renderScreen = (
  screen: AppScreen,
  send: (ev: AppEvent) => void,
  apiCx: AppApiContext,
): JSX.Element =>
  match(
    screen,
    {
      FetchingRewardVoucher: ({ rewardUniqueId }) => (
        <FetchingRewardVoucher
          api={{
            fetchRewardVoucher: () => queryRewardVoucher(rewardUniqueId, apiCx),
          }}
          onDone={(resp) => {
            switch (resp.tag) {
              case "ok":
                send(AppEvent.FetchedRewardVoucher({ rewardVoucher: resp.rewardVoucher }))
                break

              case "error":
              case "unexpectedError":
                send(
                  AppEvent.EncounteredGeneralError({
                    errorType: "rewardUniqueLinkError",
                  }),
                )
                break
            }
          }}
        />
      ),
      ClaimRewardScreen: (props) => <ClaimReward {...props} />,
      GeneralError: () => <GeneralErrorScreen />,
    },
    // There shouldn't ever be a case here, but just in case...
    (_) => <GeneralErrorScreen />,
  )

export function initReward(
  apiCx: AppApiContext,
  isDevToolsEnabled: boolean,
): InitialData<AppState, AppScreen> {
  const { rewardUniqueId } = apiCx

  const logger = createLoggingContext()

  if (isDevToolsEnabled) {
    const restore = localStorage.getItem(DEBUG_RESTORE_KEY) === "yes"
    localStorage.removeItem(DEBUG_RESTORE_KEY)

    if (restore) {
      const serialized = localStorage.getItem(DEBUG_STORAGE_KEY)
      if (serialized) {
        const { state, apiCx }: { state: AppState; apiCx: AppApiContext } = JSON.parse(serialized)

        return {
          initialState: { state, screens: [calculateRewardScreen(state)] },
          apiCx,
          authCx: createAuthContext(apiCx),
          logging: logger,
        }
      }
    }
  }

  const initialState: ShellState<AppState, AppScreen> = rewardUniqueId
    ? {
        state: AppState.FetchingRewardVoucher({ rewardUniqueId }),
        screens: [AppScreen.FetchingRewardVoucher({ rewardUniqueId })],
      }
    : {
        state: AppState.GeneralError(),
        screens: [AppScreen.GeneralError()],
      }

  anonymousIdPromise?.then((id) => {
    apiCx.anonymousId = id
  })

  return {
    initialState,
    apiCx,
    authCx: createAuthContext(apiCx),
    logging: logger,
  }
}
