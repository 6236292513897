import { VStack } from "@pomebile/primitives"
import { ScreenFrame } from "../components/ScreenFrame"
import { useEffect } from "react"
import { useLogging } from "../utils/logging"
import { RewardVoucherResponse } from "../api/webRoutes"

interface FetchingRewardVoucherProps {
  api: {
    fetchRewardVoucher: () => Promise<RewardVoucherResponse>
  }

  onDone: (uniqueLinkResponse: RewardVoucherResponse | { tag: "unexpectedError" }) => void
}

export function FetchingRewardVoucher({
  api: { fetchRewardVoucher },
  onDone,
}: FetchingRewardVoucherProps) {
  const { logError } = useLogging()

  useEffect(() => {
    fetchRewardVoucher()
      .then((resp) => {
        onDone(resp)
      })
      .catch((err) => {
        logError(err)
        onDone({ tag: "unexpectedError" })
      })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <ScreenFrame>
      <VStack alignItems="center" justifyContent="center" height="full">
        <img src="images/pomelo_spinner_gray.gif" alt="Loading Spinner" width={60} height={60} />
      </VStack>
    </ScreenFrame>
  )
}
