// Design: Will probably only have 3, largest is on button.
// 8, 24, might not even use 12
export const radii = {
  xs: "0.25rem",
  sm: "0.5rem",
  md: "0.75rem",
  lg: "1.5rem",
} as const

export type Radii = keyof typeof radii
export type RadiiValue = (typeof radii)[Radii]
