import { HStack, Stack, Txt, VStack } from "@pomebile/primitives"
import { ScreenFrame } from "../components/ScreenFrame"
import { CopyIcon, GiftIcon } from "@pomebile/design-system"
import { backgroundColors, colorPalette, radii } from "@pomebile/primitives/tokens"

interface ClaimRewardProps {
  senderName: string
  description: string
  voucherCode: string
  expirationDate: Date
}

const GrabCard = () => {
  return (
    <div
      style={{
        width: "100%",
        aspectRatio: 345 / 204,
      }}
    >
      <svg width="100%" height="100%" preserveAspectRatio="xMidYMid meet" viewBox="0 0 345 204">
        <defs>
          <linearGradient
            id="paint0_linear_5753_19927"
            x1="301.5"
            y1="0"
            x2="33.2102"
            y2="198.888"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0" stopColor="#00B14F" stopOpacity="0.9" />
            <stop offset="0.275" stopColor="#00B14F" />
            <stop offset="0.45" stopColor="#00B14F" stopOpacity="0.8" />
            <stop offset="0.64" stopColor="#00B14F" stopOpacity="0.9" />
            <stop offset="0.91" stopColor="#00B14F" />
          </linearGradient>
        </defs>
        <g>
          <rect
            x={0}
            y={0}
            width={345}
            height={204}
            rx={12}
            fill="url(#paint0_linear_5753_19927)"
          />
          <path
            d="M31.2427 40.8543C31.2427 46.5378 35.5306 50.8966 41.076 50.8966C46.4108 50.8966 49.0957 47.4445 49.0957 43.6439V42.2833H41.3201V44.3753H46.9686C47.0037 46.4327 45.1207 48.8384 41.076 48.8384C36.6125 48.8384 33.5091 45.526 33.5091 40.8535C33.5091 36.3553 36.8566 32.9728 41.4595 32.9728C44.4581 32.9728 46.8292 33.7054 49.026 35.2404L49.1657 35.1357V33.1831C47.5269 32.0323 44.7372 30.9154 41.3898 30.9154C35.7059 30.9167 31.2427 35.3101 31.2427 40.8543ZM28 40.8543C28 48.3861 33.5788 54 41.076 54C47.9103 54 52.0947 49.7121 52.0947 42.7723V39.2166H41.3201V41.2369H50.1433V43.1199C50.1433 48.5597 46.7625 51.943 41.0773 51.943C34.8355 51.943 30.198 47.1662 30.198 40.8547C30.198 34.7176 35.0796 29.8707 41.391 29.8707C44.3897 29.8707 47.1096 30.6033 49.167 32.0327V29.9754C47.1793 28.5459 44.5641 27.8137 41.391 27.8137C33.8229 27.8133 28 33.5681 28 40.8543Z"
            fill="white"
          />
          <path
            d="M56.5232 42.6325V53.4071H58.5802V42.6676C58.5802 40.2614 60.1494 38.5528 62.4859 38.5528C63.1831 38.5528 63.8106 38.7272 64.4731 39.0064C64.7523 38.3438 65.031 37.786 65.3802 37.2628C64.648 36.7746 63.5661 36.4608 62.5209 36.4608C58.9986 36.4608 56.5232 39.0064 56.5232 42.6325ZM53.4895 53.4071H55.5469V42.6325C55.5469 38.3784 58.4062 35.4845 62.556 35.4845C63.9508 35.4845 65.3802 35.938 66.0077 36.3215C66.3916 35.8679 66.81 35.3798 67.3683 34.9267C66.0782 34.0196 64.3346 33.4968 62.4516 33.4968C56.9771 33.4968 53.4903 37.1931 53.4903 42.6676L53.4895 53.4071Z"
            fill="white"
          />
          <path
            d="M85.743 41.3424V44.3761C86.964 42.7372 88.011 41.3074 88.986 40.122C91.46 37.0883 93.624 35.4148 96.971 35.4148C101.504 35.4148 104.817 38.832 104.817 43.6439C104.817 48.3511 101.574 51.9426 97.111 51.9426C93.482 51.9426 90.346 49.4688 89.614 46.0847L88.149 48.0724C89.3 51.3848 92.682 54 97.076 54C102.724 54 106.874 49.4672 106.874 43.6439C106.874 37.7509 102.689 33.3208 96.972 33.3208C91.044 33.3208 88.324 37.7509 85.743 41.3424Z"
            fill="white"
          />
          <path
            d="M92.124 42.8766H92.055L90.45 45.0733C90.73 47.5842 93.275 50.9317 97.111 50.9317C101.016 50.9317 103.84 47.757 103.84 43.6088C103.84 39.4607 100.946 36.3912 96.971 36.3912C93.101 36.3912 90.869 39.2154 89.3 41.3074C88.533 42.3188 87.208 43.9577 85.743 46.015V49.3625C87.104 47.3401 88.951 44.969 90.311 43.3301C92.612 40.5752 94.007 38.4832 96.936 38.4832C99.796 38.4832 101.748 40.6799 101.748 43.6088C101.748 46.6425 99.9 48.8743 97.111 48.8743C94.183 48.8743 92.124 46.1894 92.124 43.1904V42.8766Z"
            fill="white"
          />
          <path
            d="M90.067 26V34.6814C90.694 34.1932 91.392 33.6003 92.124 33.3208V26H90.067ZM86.999 37.856C87.627 37.0541 88.324 36.1821 89.056 35.4499V26H86.999V37.856Z"
            fill="white"
          />
          <path
            d="M78.6294 47.7924L78.5944 47.7578C77.7228 48.455 76.2933 48.8735 74.9332 48.8735C72.1435 48.8735 70.0515 46.7811 70.0515 43.6785C70.0515 40.7149 72.1089 38.4481 74.8635 38.4481C77.8271 38.4481 79.6412 40.6102 79.6412 43.5045V53.4071H81.6981V43.3998C81.6981 39.3548 78.8739 36.3907 74.8989 36.3907C70.9586 36.3907 67.9249 39.3543 67.9249 43.6781C67.9249 47.966 70.9235 51.0356 74.8989 50.9313C76.398 50.8962 77.9326 50.3384 78.6298 49.8502L78.6294 47.7924Z"
            fill="white"
          />
          <path
            d="M64.8219 43.6439C64.8219 49.7113 69.1098 54 74.8993 54C76.1894 54 77.6889 53.6166 78.6302 52.8843V50.827C78.0027 51.3502 76.4681 51.9426 74.8993 51.9426C70.1579 51.9426 66.949 48.5952 66.949 43.6439C66.949 38.9367 70.3661 35.4148 74.8993 35.4148C79.3975 35.4148 82.6753 38.7272 82.6753 43.3648V53.4071H84.767V43.3301C84.767 37.5419 80.6876 33.2878 74.8993 33.2878C69.2154 33.2878 64.8219 37.786 64.8219 43.6439Z"
            fill="white"
          />
        </g>
      </svg>
    </div>
  )
}

export function ClaimReward({
  senderName,
  description,
  voucherCode,
  expirationDate,
}: ClaimRewardProps) {
  const handleCopyVoucherCode = () => {
    navigator.clipboard.writeText(voucherCode)
  }

  return (
    <ScreenFrame>
      <VStack justifyContent="space-between" gap="xl4">
        <VStack gap="xs">
          <Txt variant="headline2">{senderName} sent you a gift</Txt>
          <Txt>Copy the voucher code to redeem in your Grab account.</Txt>
        </VStack>
        <div
          style={{
            position: "relative",
            backgroundColor: backgroundColors["background-bordered-callout-default"],
            borderRadius: radii["md"],
          }}
        >
          <GrabCard />
          <div
            style={{
              position: "absolute",
              top: 0,
              right: 0,
              padding: 26,
              justifyContent: "space-between",
            }}
          >
            <Stack direction="row" justifyContent="flex-end">
              <GiftIcon width={32} height={32} fill="icon-inverse" />
            </Stack>
          </div>
          <div
            style={{
              position: "absolute",
              top: 130,
              left: 0,
              padding: 26,
              justifyContent: "space-between",
            }}
          >
            <Stack>
              <Txt variant="headline3" color="text-inverse">
                Grab car
              </Txt>
              <Txt color="text-inverse">{description}</Txt>
            </Stack>
          </div>
          <VStack padding={{ y: "sm", x: "xl2" }}>
            <HStack justifyContent="space-between" width="full" alignItems="center">
              <VStack flex={1}>
                <Txt variant="body2">Your gift voucher code</Txt>
                <Txt variant="subtitle2">{voucherCode}</Txt>
              </VStack>
              <Stack width="fitContent">
                <div onClick={handleCopyVoucherCode}>
                  <CopyIcon width={24} height={24} />
                </div>
              </Stack>
            </HStack>
          </VStack>
        </div>
        <VStack>
          <Txt variant="body2" color="text-caption">
            Valid until{" "}
            {expirationDate.toLocaleDateString("en-US", {
              year: "numeric",
              month: "long",
              day: "numeric",
            })}
          </Txt>
        </VStack>
      </VStack>
    </ScreenFrame>
  )
}
