import { Svg, Path } from "@pomebile/primitives"
import { IconProps } from "./icon-contract"

export const CopyIcon = ({ width = 24, height = 24, fill }: IconProps) => {
  return (
    <Svg width={width} height={height} viewBox="0 0 24 24" customFill="none">
      <Path
        d="M20.25 3.00024H8.25C8.05109 3.00024 7.86032 3.07926 7.71967 3.21991C7.57902 3.36057 7.5 3.55133 7.5 3.75024V7.50024H3.75C3.55109 7.50024 3.36032 7.57926 3.21967 7.71991C3.07902 7.86057 3 8.05133 3 8.25024V20.2502C3 20.4492 3.07902 20.6399 3.21967 20.7806C3.36032 20.9212 3.55109 21.0002 3.75 21.0002H15.75C15.9489 21.0002 16.1397 20.9212 16.2803 20.7806C16.421 20.6399 16.5 20.4492 16.5 20.2502V16.5002H20.25C20.4489 16.5002 20.6397 16.4212 20.7803 16.2806C20.921 16.1399 21 15.9492 21 15.7502V3.75024C21 3.55133 20.921 3.36057 20.7803 3.21991C20.6397 3.07926 20.4489 3.00024 20.25 3.00024ZM15 19.5002H4.5V9.00024H15V19.5002ZM19.5 15.0002H16.5V8.25024C16.5 8.05133 16.421 7.86057 16.2803 7.71991C16.1397 7.57926 15.9489 7.50024 15.75 7.50024H9V4.50024H19.5V15.0002Z"
        fill={fill}
      />
    </Svg>
  )
}
